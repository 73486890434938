import React, { Component } from "react";
import "./UserOverlay.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPencil } from "@fortawesome/pro-light-svg-icons";
import Profile from "./Panels/Profile";
import Activity from "./Panels/Activity";
import Permission from "./Panels/Permission";
import Settings from "./Panels/Settings";
import Dropzone from "react-dropzone";
import env from "../../../../environment.json";
import globalObject from "../../../../assets/globalVariables";
import axios from "axios";
import SquareButton from "src/Components/Buttons/SquareButton/SquareButton";
import VerifyMethods from "src/assets/VerifyMethods";
import { getUsers, uploadMedia } from "src/assets/helperFunctions";
import { cloneDeep } from "lodash";
import { usePermCheckStore } from "src/Components/PermCheck/PermCheckStore";
import { useUserStore } from "src/Components/ZustandStores/userStore";

function verifySave(email) {
  var verifyEmail = false;
  VerifyMethods.validEmail(email).length === 0 ? (verifyEmail = true) : (verifyEmail = false);
  return verifyEmail;
}

class UserOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
      checkboxFilter: { siteType: ["all"], format: ["all"] },
      update: false,
      onsavePopText: "",
      uploadFilePercent: 100,
      draggingOnUserImage: false,
      allPlaces: [],
      placesICanChoose: [],
      accountStatus: "",
      data: cloneDeep(this.props.data),
      permissionChanges: {},
      canChangePersonal: false,
      response: "",
      responseIsError: false,
    };

    this.imgConfig.onUploadProgress = this.imgConfig.onUploadProgress.bind(this);
  }

  componentDidMount() {
    axios
      .get(env.protocol + env.env + "/api/whatson/places/GetPlacesList")
      .then((response) => {
        this.setState({
          allPlaces: response.data.map((e) => {
            return { label: `${e.headline}`, value: e.id };
          }),
        });
      })
      .catch((error) => console.error(error));

    axios
      .get(
        env.protocol +
          env.env +
          "/api/whatson/places/GetPlacesList?" +
          (globalObject.vfiUser.superAdmin === "1" ? "" : `author=${globalObject.vfiUser.userId}&isPlaceAdmin`)
      )
      .then((response) => {
        this.setState({
          placesICanChoose: response.data,
        });
      })
      .catch((error) => console.error(error));

    if (this.state.data.id) {
      axios
        .get(env.protocol + env.env + "/api/whatson/places/GetPlacesList?author=" + this.state.data.id)
        .then((response) => {
          this.onChange(
            "places",
            response.data.map((e) => {
              return { label: e.headline, value: e.id, is_admin: e.is_admin };
            })
          );
        })
        .catch((error) => console.error(error));
    } else {
      this.onChange("places", []);
    }

    const { loggedInUser } = useUserStore.getState();
    this.setState({
      canChangePersonal: loggedInUser.userId === this.state.data.userId || loggedInUser.superAdmin ? true : false,
    });
  }

  handleSave = () => {
    this.saveData();

    if (this.state.readyToUpdate) {
      this.state.readyToUpdate();
    }

    setTimeout(() => {
      this.setState({
        update: false,
      });

      setTimeout(() => {
        this.props.setResponse("");
      }, 3000);
    }, 100);
  };

  showUpdateBtn = () => {
    if (!this.state.update) {
      //Only update if already false
      this.setState({ update: true });
    }
  };

  handleReadyToUpdate = (readyToUpdate) => {
    if (readyToUpdate) {
      this.setState({ readyToUpdate });
    }
  };

  onDrop = (e, imgConfig) => {
    // this.props.uploadPhoto(e, imgConfig);
    uploadMedia(e, "0", this.config, (response) => {
      console.log(response);
      const user_image = response.data[0];
      if (user_image) {
        const { id, file_extension } = user_image;
        console.log(this.state.data);
        this.setState({ data: { ...this.state.data, localImg: id, localImg_extension: file_extension, user_image } });
        this.showUpdateBtn();
      }
    });
  };

  renderSwitch() {
    switch (this.props.panel.toLowerCase()) {
      case "profile":
        return (
          <Profile
            data={this.state.data}
            onChange={this.onChange}
            update={this.state.update}
            showUpdateBtn={this.showUpdateBtn}
            extra={this.props.extra}
          />
        );

      case "activity":
        return (
          <Activity
            userClicked={this.state.data.userId}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            checkboxFiltering={this.props.checkboxFiltering}
            SetFilterData={this.props.SetFilterData}
            listOptions={this.props.listOptions}
            addFilterDroppedDown={this.props.addFilterDroppedDown}
            openCloseFilter={this.props.openCloseFilter}
            SetState={this.props.SetState}
          />
        );

      case "permission":
        return (
          <Permission
            data={this.state.data}
            handleOptionChange={this.props.handleOptionChange}
            clearAllOptionChange={this.props.clearAllOptionChange}
            onChange={this.onChange}
            permissionChange={(type, key, value) => {
              const permissionChanges = this.state.permissionChanges;
              if (!permissionChanges[type]) {
                permissionChanges[type] = [];
              }
              const index = permissionChanges[type].findIndex((element) => element[key] === value[key]);
              if (index !== -1) {
                permissionChanges[type][index] = value;
              } else {
                permissionChanges[type].push(value);
              }
              this.setState({ permissionChanges });
            }}
            update={this.state.update}
            showUpdateBtn={this.showUpdateBtn}
            extra={this.props.extra}
            allPlaces={this.state.allPlaces}
            placesICanChoose={this.state.placesICanChoose}
            openOverlay={this.props.openOverlay}
          />
        );
      case "settings":
        if (this.state.canChangePersonal)
          return (
            <Settings
              data={this.state.data}
              close={() => this.props.close()}
              SetState={this.props.SetState}
              // accountStatus={this.state.accountStatus}
              setAccountStatus={(key, value) => {
                this.setState({ [key]: value });
              }}
              showUpdateBtn={this.showUpdateBtn}
              onReadyToUpdate={this.handleReadyToUpdate}
              // userClicked={this.state.data.id}

              // startDate={this.props.startDate}
              // endDate={this.props.endDate}
              // checkboxFiltering={this.props.checkboxFiltering}
              // SetFilterData={this.props.SetFilterData}
              // listOptions={this.props.listOptions}
              // addFilterDroppedDown={this.props.addFilterDroppedDown}
              // openCloseFilter={this.props.openCloseFilter}
              // SetState={this.props.SetState}
            />
          );

      default:
        return "";
    }
  }

  // lastElm(array) {
  //   let returnArray = this.state.data.loginDates;
  //   let lastelm = JSON.parse(returnArray).slice(-1)[0];
  //   return lastelm;
  // }

  setPanelState(param) {
    param = param.toLowerCase();
    switch (param) {
      case "profile":
        this.setState({ panel: "profile" });
        this.props.SetState("panel", "profile");
        break;
      case "activity":
        this.setState({ panel: "activity" });
        this.props.SetState("panel", "activity");
        break;
      case "permission":
        this.setState({ panel: "permission" });
        this.props.SetState("panel", "permission");
        break;
      case "settings":
        this.setState({ panel: "settings" });
        this.props.SetState("panel", "settings");
        break;
      default:
        return "";
    }
  }

  saveData = () => {
    if (verifySave(this.state.data.email)) {
      var user = this.state.data;

      if (user.userId) {
        //Not new user, save changes made
        this.updateUser();
      } else {
        //New user
        this.newUser();
      }
    } else {
      this.setState({ response: "You have to enter valid email", responseIsError: true });
    }
  };

  updateUser = () => {
    axios
      .post(env.protocol + env.env + "/api/secured/updateUser", {
        userData: { ...this.state.data, permissions: this.state.permissionChanges },
        vfiUser: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        this.setState({ response: response.data, responseIsError: false });
        setTimeout(() => {
          this.setState({ response: "" });
        }, 5000);
        // this.editUserContent(); //when user er edited or added we want to add it manually until child rerenders
      })
      .catch((error) => {
        this.setState({ response: error.message, responseIsError: true });
        setTimeout(() => {
          this.setState({ response: "", responseIsError: false });
        }, 5000);
      });
  };

  newUser = () => {
    //Create user
    axios
      .post(env.protocol + env.env + "/api/secured/createUser", {
        userData: this.state.data,
        vfiUser: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        // getUsers(undefined, (response) => this.handleUserData(response));
        useUserStore.getState().getUsers(true);

        this.setState(
          {
            response: response.data.split(";")[1],
            responseIsError: false,
            data: {
              ...this.state.data,
              id: response.data.split(";")[0],
            },
          },
          () => {
            // TODO: Update the users list that this user has access to.
            // Right now, addUserContent is disabled, as it isn't implemented. The plan is to use `users` list in userStore.
            // this.addUserContent()
          }
        ); //when user er edited or added we want to add it manually until child rerenders);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  onChange = (name, value, afterFunc = () => void 0) => {
    if (Array.isArray(name)) {
      const data = cloneDeep(this.state.data);
      name.forEach((n, i) => {
        data[n] = value[i];
      });
      this.setState({ data: data }, afterFunc);
    } else {
      this.setState(
        {
          data: {
            ...this.state.data,
            [name]: value,
          },
        },
        afterFunc
      );
    }
  };

  JsonValue() {
    return {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };
  }

  imgConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      this.setState({
        uploadFilePercent: percentCompleted,
      });
    },
  };
  render() {
    const uploadedPhoto = this.state.data.uploadedPhoto;
    return (
      <div className="wrapper">
        <div className={"user-panel " + this.props.extra}>
          <div className="top-header">
            {this.state.accountStatus == "deleted" && (
              <div className="account-status">
                <h2>DELETED USER</h2>
              </div>
            )}
            <div className="header">
              <div
                className="close"
                onClick={() => {
                  this.props.close();
                }}
              >
                <p className="icon">
                  <FontAwesomeIcon icon={faTimes} />
                </p>
              </div>
              <div className="userInfo">
                <div className="dragndrop-photo-parent">
                  <Dropzone onDrop={(e) => this.onDrop(e, this.imgConfig)} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} accept=".jpg,.jpeg,.png" maxLength={1} />
                          {uploadedPhoto !== undefined && uploadedPhoto.length > 0 ? ( //Vísir myndina so skjótt tað verður uploada
                            <div className={"profile_pic" + (this.state.uploadFilePercent !== 100 ? " uploading" : "")}>
                              <img src={uploadedPhoto} alt="user img" />
                              {this.state.uploadFilePercent !== 100 ? (
                                <div className="upload-bar">
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width: this.state.uploadFilePercent + "%",
                                    }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <FontAwesomeIcon icon={faPencil} />
                            </div>
                          ) : //If no new uploaded image is up there is a picture in the database connected
                          parseInt(this.state.data.localImg) > 0 ? (
                            <div className={"profile_pic" + (this.state.uploadFilePercent !== 100 ? " uploading" : "")}>
                              <img
                                src={
                                  env.protocol +
                                  env.env +
                                  "/uploads/" +
                                  this.state.data.user_image.file_name +
                                  "_thumbnail." +
                                  this.state.data.localImg_extension
                                }
                                alt="user img"
                              />
                              <FontAwesomeIcon icon={faPencil} />
                            </div>
                          ) : (
                            //No image
                            <div
                              className={
                                "upload-box" +
                                (this.state.uploadFilePercent !== 100 ? " uploading" : "") +
                                (this.state.draggingOnUserImage === true ? " on-drag" : "")
                              }
                              onDragEnter={() => this.setState({ draggingOnUserImage: true })}
                              onDragLeave={() => this.setState({ draggingOnUserImage: false })}
                            >
                              {<div className="text-content">Drag and drop photo or select file</div>}
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                {/* }  */}

                <p className="name">{this.state.data.firstname + " " + this.state.data.lastname}</p>
                <p className="lastlogin">
                  {this.state.data.loginDates?.length <= 0 || this.state.data.loginDates === undefined
                    ? ""
                    : "Last login " + JSON.parse(this.state.data.loginDates).slice(-1)[0]}
                </p>
              </div>
            </div>
            <div className="panels">
              <ul>
                <li
                  className={this.props.panel === "profile" ? "Profile Active" : "Profile"}
                  onClick={() => {
                    this.setPanelState("Profile");
                  }}
                >
                  PROFILE
                </li>
                <li
                  className={this.props.panel === "activity" ? "Activity Active" : "Activity"}
                  onClick={() => {
                    this.setPanelState("Activity");
                  }}
                >
                  ACTIVITY
                </li>
                <li
                  className={this.props.panel === "permission" ? "Permission Active" : "Permission"}
                  onClick={() => {
                    this.setPanelState("Permission");
                  }}
                >
                  PERMISSIONS
                </li>
                {this.state.canChangePersonal && (
                  <li
                    className={this.props.panel === "settings" ? "Settings Active" : "Settings"}
                    onClick={() => {
                      this.setPanelState("Settings");
                    }}
                  >
                    SETTINGS
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="body">
            {this.renderSwitch()}
            <p className={this.props.response.length > 0 ? "SaveText" : "SaveTextHide"}>{this.props.response}</p>

            {this.state.update ? (
              <div style={{ display: "flex" }}>
                <SquareButton
                  onClick={() => this.handleSave()}
                  // style={this.buttonStyle}
                  className="save"
                >
                  {this.props.extra === "editUser" ? "Update profile changes" : "Create user"}
                </SquareButton>
              </div>
            ) : (
              ""
            )}

            <div className={`SaveText ${this.state.responseIsError ? "error" : ""}`}>{this.state.response}</div>
          </div>
        </div>
      </div>
    );
  }
}
export default UserOverlay;
